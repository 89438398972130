"use strict";
window.megaMenu = {
    openNav: openNav,
    closeNav: closeNav,
    toggleSubMenu: toggleSubMenu
};
function openNav() {
    // set class on body to hide scrollbar on mobile
    document.getElementsByTagName("body")[0].classList.toggle("open");
    // mark overlay menu as open
    document.getElementById("myNav").classList.toggle("show");
    // mark navbar as open
    document.getElementsByClassName("navbar")[0].classList.toggle("menuOpen");
    document.getElementsByClassName('headerblock')[0].classList.toggle("menuOpen");
    // hide support chat button
    var chatButton = document.getElementsByClassName('genesys-banner');
    if (chatButton.length > 0) {
        chatButton[0].style.display = "none";
    }
}
function closeNav() {
    // set class on body to hide scrollbar on mobile
    document.getElementsByTagName("body")[0].classList.toggle("open");
    // mark overlay menu as closed
    document.getElementById("myNav").classList.toggle("show");
    // mark navbar as closed
    document.getElementsByClassName("navbar")[0].classList.toggle("menuOpen");
    document.getElementsByClassName('headerblock')[0].classList.toggle("menuOpen");
    // show support bubble
    var chatButton = document.getElementsByClassName('genesys-banner');
    if (chatButton.length > 0) {
        chatButton[0].style.display = "block";
    }
}
function toggleSubMenu(subMenuId) {
    subMenuId.classList.toggle("show");
    document.getElementsByClassName("megameny-nav")[0].classList.toggle("subMenuOpen");
    document.getElementsByClassName("megameny-bottom-nav")[0].classList.toggle("subMenuOpen");
    document.getElementById("mobileNavButtons").classList.toggle("subMenuOpen");
    // set back button to hide correct submenu
    document.getElementsByClassName("backToMenu")[0].setAttribute("onClick", "megaMenu.toggleSubMenu(" + subMenuId.id + ")");
}
