import '@babel/polyfill';
import '@mdi/font/css/materialdesignicons.css';
import './megaMenu';
import './Fund/CurrencyUpdater';
import './Apsis/ApsisTasks';
import './Apsis/newsletterFlyout';
import './Fund/FundPage';
import './Fund/SimpleFundList';
import './Pages/main';
import './Pages/theme-article-page';
import './Pages/theme-page';
import './ToolTip/toolTipSearch';
// Moved an update scripts
import './skagenweb';
import './replaceText';
import './jPaginate';
import './responsive-tables';
import './placeholder';
import './Common/SetupDatePicker';
import { trackPageView } from './components/GoogleTagManager';
require('jquery/dist/jquery');
require('jquery-validation/dist/jquery.validate');
require('jquery-ajax-unobtrusive');
require('jquery-validation-unobtrusive');
require('bootstrap-sass');
trackPageView();
