"use strict";
$(function () {
    var query = "";
    var toolTipResults;
    $(".article-content__main-body span.toolTip").each(function (index, element) {
        query += "toolTipNames=" + $(element).text() + "&";
    });
    if (query) {
        $.get("/api/tooltips/?" + query, function (data) {
            toolTipResults = data;
            $(".article-content__main-body span.toolTip").each(function (index, element) {
                var toolTipDescription = searchToolTips(element.textContent);
                if (toolTipDescription) {
                    $(element).attr("title", toolTipDescription);
                    $(element).attr("data-toggle", "tooltip");
                    $(element).attr("data-html", "true");
                    $(element).attr("data-placement", "top");
                    $(element).attr("data-template", '<div class="tooltip tooltipSearch" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>');
                    $(element).addClass("active");
                }
            });
            // To ensure tooltips are shown
            $('[data-toggle="tooltip"]').tooltip();
        });
    }
    function searchToolTips(query) {
        var result = "";
        $.each(toolTipResults, function (index, element) {
            result = "";
            if (element.Name.toLowerCase() === query.toLowerCase()) {
                result = element.Detail;
                return false;
            }
        });
        return result;
    }
});
