var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { hasMarketingConsent } from '../Consent/consentUtil';
import { trackTradidionalGaEvent, EventInfoCategory, EventInfoAction, } from "../components/GoogleTagManager";
/**
 * Shows a flyout used to sign up for newsletters.
 * - Flyouts should not show at the same time as or straight after cookie consent dialogs (handled by visitor groups).
 * - Flyouts should only appear to users that have consented to marketing.
*/
$(function () {
    var _this = this;
    // Form logic //
    var submitForm = function (form) { return __awaiter(_this, void 0, void 0, function () {
        var result;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    form.classList.add('collapsed');
                    return [4 /*yield*/, postEmailAsync(form.email.value)];
                case 1:
                    result = _a.sent();
                    setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (result.status === 200) {
                                showFeedback(form.parentElement, true);
                                localStorage.setItem('flyout-signup-completed', true);
                                trackTradidionalGaEvent(EventInfoCategory.Newsletter, EventInfoAction.Button, "flyout-signup-completed");
                            }
                            else {
                                showFeedback(form.parentElement, false);
                                trackTradidionalGaEvent(EventInfoCategory.Newsletter, EventInfoAction.Button, "flyout-signup-failed");
                            }
                            return [2 /*return*/];
                        });
                    }); }, 500);
                    return [2 /*return*/];
            }
        });
    }); };
    var postEmailAsync = function (emailAddress) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch('/api/apsis/flyout/subscribe/', {
                        method: 'POST',
                        body: JSON.stringify(emailAddress),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var showFeedback = function (containerElement, success) { return __awaiter(_this, void 0, void 0, function () {
        var feedback;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    feedback = containerElement.querySelector('[data-newsletter-flyout-feedback]');
                    if (!success) return [3 /*break*/, 3];
                    return [4 /*yield*/, playCheckmarkAnimation(feedback, 2000)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, showMessage(feedback, 'success', 5000)];
                case 2:
                    _a.sent();
                    hideFlyouts();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, showMessage(feedback, 'error', 4000)];
                case 4:
                    _a.sent();
                    containerElement.querySelector('[data-newsletter-flyout-form]').classList
                        .remove('collapsed');
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var playCheckmarkAnimation = function (parentElement, duration) {
        return new Promise(function (resolve) {
            var checkmark = parentElement.querySelector('svg');
            checkmark.classList.add('show');
            setTimeout(function () {
                checkmark.classList.remove('show');
                resolve();
            }, duration);
        });
    };
    var showMessage = function (parentElement, messageType, duration) {
        return new Promise(function (resolve) {
            var message = parentElement.querySelector('.message.' + messageType);
            message.classList.add('show');
            setTimeout(function () {
                message.classList.remove('show');
                resolve();
            }, duration);
        });
    };
    // Init //
    var bindFlyoutButtons = function () {
        var flyoutButtons = document.querySelectorAll('[data-newsletter-flyout-button]');
        flyoutButtons.forEach(function (button) {
            button.addEventListener('click', function (event) {
                var flyout = event.target.closest('[data-newsletter-flyout]');
                flyout.classList.toggle('extended');
                var flyoutExtended = flyout.classList.contains('extended');
                trackTradidionalGaEvent(EventInfoCategory.Newsletter, EventInfoAction.Button, flyoutExtended ? "user-opened-flyout" : "user-closed-flyout");
            });
        });
    };
    var bindFlyoutForms = function () {
        var forms = document.querySelectorAll('[data-newsletter-flyout-form]');
        forms.forEach(function (f) { return f.addEventListener('submit', function (e) {
            e.preventDefault();
            submitForm(e.target);
        }); });
    };
    var showFlyouts = function () {
        document.querySelectorAll('[data-newsletter-flyout]').forEach(function (flyoutElement) {
            flyoutElement.classList.remove('flown-out');
            trackTradidionalGaEvent(EventInfoCategory.Newsletter, EventInfoAction.None, "flyout-shown-to-user");
        });
    };
    var hideFlyouts = function () {
        document.querySelectorAll('[data-newsletter-flyout]').forEach(function (flyoutElement) {
            flyoutElement.classList.add('flown-out');
            flyoutElement.classList.remove('extended');
        });
    };
    var getMinutesSinceFirstVisit = function () {
        var firstVisit = parseInt(localStorage.getItem('flyout-first-page-visit'));
        if (!firstVisit) {
            firstVisit = Date.now();
            localStorage.setItem('flyout-first-page-visit', firstVisit);
        }
        return (Date.now() - firstVisit) / 1000 / 60;
    };
    var getShowDelay = function () {
        try {
            return parseInt(document.querySelector('[data-flyout-delay-minutes]')
                .dataset['flyoutDelayMinutes']);
        }
        catch (_a) {
            return 0;
        }
    };
    var userHasSignedUp = function () { return !!localStorage.getItem('flyout-signup-completed'); };
    // Initialize if all conditions are met
    if (hasMarketingConsent() && !userHasSignedUp()
        && getMinutesSinceFirstVisit() > getShowDelay()) {
        bindFlyoutButtons();
        bindFlyoutForms();
        setTimeout(showFlyouts, 5000);
    }
});
