import Cookies from 'js-cookie';
import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';
stockInit(Highcharts);
var Handlebars = require('handlebars');
// Object that contains fundpage functions
window.fundpage = {};
$(function () {
    /* Cip FundPage */
    if ($('.modulblock.fundmodul').length > 0) {
        var awardsDiv = $('#cipAwardsArea').html();
        var $div = $('.modulblock.fundmodul').parent();
        $(awardsDiv).appendTo($div);
        //Move Fundcost text to Fundmodul, after rating
        var costsDiv = $('#fund-costs').html();
        $(costsDiv).appendTo($div);
        //Move disclaimer to correct place on fundpage
        var DisclaimerText = $('.container.dislaimerTextArea.disclaimertext p').html();
        $('.container.dislaimerTextArea.disclaimertext').hide();
        $('#cipDisclaimerArea').html(DisclaimerText);
    }
    /* AllfundsTabBlock - Remembering the tab selection for next time*/
    $('.allfunds .nav-tabs a').click(function (e) {
        var activeTab = e.target.hash.substr(1);
        Cookies.set('allfundsactivetab2', activeTab, { path: window.location.pathname, expires: 365, });
        window.location.hash = e.target.hash;
    });
    /* AllfundsTabBlock - Make sure last active tab is current tab*/
    if ($('.allfunds .nav-tabs').length > 0) {
        var hash = window.location.hash;
        if (!hash) {
            var cookieTab = Cookies.get('allfundsactivetab2');
            if (cookieTab) {
                hash = "#" + cookieTab;
            }
        }
        if (hash && hash.match("^#fundTab")) {
            $('.allfunds .nav-tabs a[href="' + hash + '"]').tab('show');
        }
        else {
            // activate first tab by default
            $('.allfunds .nav-tabs a:first').tab('show');
        }
    }
    var graphtexts = {};
    var $container = $('#container');
    var fundpagechart;
    $("#fundsdd").change(function () {
        refreshGraph(graphtexts);
    });
    /* Contains fundgraph data*/
    var json;
    function createChart(grapContainer, isin, currency, date) {
        var webMethod = '/api/FundGathering/GetGraphData?';
        var parameters = { isin: isin, currency: currency, date: date };
        $(".fundtable").addClass("spin");
        $.getJSON(webMethod, parameters, function (jsontmp) {
            json = jsontmp;
            if (json.graph !== null && json.graph.length !== 0 && json.graph[1] !== undefined) {
                json.graph[1].name = graphtexts.index;
            }
            if ($("#graph-view").hasClass("in")) {
                drawGraph();
                $(".choose-currency-area").css("visibility", "hidden");
                $(".changeyearrangearea").css("display", "block");
                $(".changedatetable").css("display", "none");
            }
            updateFundTableView(json);
            $(".fundtable").removeClass("spin");
            $(".cf .fund").text(json.fundPerformanceTable.FundName);
            $(".cf .index").text(graphtexts.index);
            if ($("#rangedropdown option:selected").val() !== 0) {
                window.fundpage.changeInDropdown();
            }
            if ($('a[href^="#graph-view"]').attr("aria-expanded") === "true") {
                drawGraph();
                window.fundpage.changeInDropdown();
            }
            // Will remove the option to select a date back in time for (data is not available)
            if (json.showReturnOnGivenDate) {
                $(".changedateInputArea").css("display", "block");
            }
            else {
                $(".changedateInputArea").css("display", "none");
            }
        });
    }
    function generateGraph(grapContainer) {
        fundpagechart = Highcharts.stockChart(grapContainer, {
            credits: {
                enabled: false,
            },
            colors: [
                "#8b9e21",
                "#3b592a",
                "#8AC2E6",
                "#ACCA57",
                "#FFD444",
                "#50A025",
                "#C9E6F6",
                "#77a1e5",
                "#c42525",
                "#a6c96a"
            ],
            rangeSelector: {
                enabled: false
            },
            chart: {
                type: "spline",
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.change}&nbsp;%</b><br/>',
                valueDecimals: 2,
                split: true,
            },
            plotOptions: {
                spline: {
                    turboThreshold: 20000,
                },
                series: {
                    //compare: 'value',
                    compare: "percent",
                    showInNavigator: true,
                },
            },
            compareBase: 0,
            yAxis: {
                opposite: false,
                labels: {
                    formatter: function () {
                        return (this.value > 0 ? " + " : "") + this.value + "%";
                    },
                },
                plotLines: [
                    {
                        value: 0,
                        width: 2,
                        color: "silver",
                    },
                ],
            },
            navigator: {
                maskInside: true,
                series: {
                    lineColor: "#333",
                    type: "areaspline",
                    color: "#fff",
                    lineWidth: 2
                },
                maskFill: "rgba(204, 204, 204, 0.40)",
                enabled: true
            },
            legend: {
                enabled: false,
                borderRadius: 1,
                borderColor: "#fff",
                useHTML: true,
                verticalAlign: "top",
                x: "screenLeft"
            },
            series: json.graph
        });
    }
    window.fundpage.changeInDropdown = function () {
        var selectIndex = $("#rangedropdown option:selected").index();
        var jsonfromdate = json.range[selectIndex].fromDate;
        var fromdate = new Date(jsonfromdate);
        var jsontodate = json.range[selectIndex].toDate;
        var todate = new Date(jsontodate);
        //Updates all the data-fields and inputs, also updates the graph
        changeofDate(fromdate, todate);
    };
    function updateFundTableView(json) {
        json = json.fundPerformanceTable || json;
        // Create viewmodel for handlebars template
        var startDate = json.StartDate;
        var hideSincestart = $(".fundgraph").data("hide-sincestart");
        var vm = { rows: json.fundTabledata };
        $(vm.rows)
            .each(function () {
            this.displayText = graphtexts[this.text];
            if (this.text === "alltime" && !hideSincestart) {
                this.displayText += " (" + startDate + ")";
            }
            this.displayTextHeading = graphtexts.period;
            this.fundName = json.FundName;
            this.indexName = graphtexts.index;
        });
        var source = $("#fundtable-template").html();
        var template = Handlebars.compile(source);
        $(".fundtable tbody").html(template(vm));
        $(".lastupdatedtable").text(json.Lastupdated);
        $("#benchmarkDateTable").text(json.BenchmarkLastupdated);
        if (json.Lastupdated !== json.BenchmarkLastupdated) {
            $("#displayBenchmarkUpdate").slideDown();
        }
        else {
            $("#displayBenchmarkUpdate").slideUp();
        }
        $("#latestprice").text(json.Latestnav);
    }
    function changeofDate(fromdate, nowTemp) {
        var chart = fundpagechart;
        if (chart !== undefined) {
            chart.xAxis[0].setExtremes(Date.UTC(fromdate.getFullYear(), fromdate.getUTCMonth(), fromdate.getUTCDate()), Date.UTC(nowTemp.getFullYear(), nowTemp.getUTCMonth(), nowTemp.getUTCDate()));
            $("#fundnamecolor").text(chart.series[0].name);
            if (json.graph[1] !== undefined) {
                $("#referencenamecolor").text(chart.series[1].name);
                $("#referencenamecolor").parent().show();
            }
            else {
                $("#referencenamecolor").parent().hide();
            }
        }
    }
    function refreshGraph(graphtext) {
        //Check if fundgraph block is on page
        if ($(".fundgraph").length) {
            var grapContainerId = "#container";
            var isin = $(".fundgraph").data("isin");
            var date = $("#tabledateinput").val();
            var currency = $("select#currencydd option:selected").val();
            $("#tabledateinput").empty();
            createChart(grapContainerId, isin, currency, date);
        }
    }
    window.fundpage.refreshFundTable = function (currency) {
        var grapContainerId = "#container";
        var isin = $(".fundgraph").data("isin");
        var date = $("#tabledateinput").val();
        $("#tabledateinput").empty();
        createChart(grapContainerId, isin, currency, date);
    };
    function drawGraph() {
        generateGraph("container");
    }
    if ($('#container').length > 0) {
        //TODO: delete thisyearapril23 when we are in 2024
        ['thisyear', 'thisyearapril23', 'lastyear', 'last3', 'last5', 'last10', 'yield', 'duration',
            'weightedaveragelife', 'accruedinterestrateprunit', 'alltime', 'alltimeapril23', 'alltime092022', 'period',
            'asof', 'index'].forEach(function setUpText(key) {
            graphtexts[key] = $container.data(key);
        });
        refreshGraph(graphtexts);
    }
    $("#germanTaxForm").submit(function (event) {
        var $Form = $(this);
        var isin = $Form.data("isin");
        // We should validate form here and not this if check
        var date = $("#dateinput").val();
        if (date.length > 0) {
            var webMethod = '/api/FundGathering/GermanTax?';
            var parameters = {
                isin: isin,
                date: date
            };
            $(".has-germanspinner").toggleClass('active');
            $.getJSON(webMethod, parameters, function (json) {
                $(".asof").css("color", "#1F9DC9");
                $(".dateasof").text(date);
                $("#akg1").text(json.akg1 + " %");
                $("#akg2").text(json.akg2 + " %");
                $("#ip").text(json.ip);
                $("#addi").text(json.addi);
                $(".has-germanspinner").toggleClass('active');
            });
        }
        event.preventDefault();
    });
    // currency selector on fundgraph block
    $("#currencydd").change(function () {
        var currency = $("select#currencydd option:selected").val();
        window.currencyUpdater.updateCurrency(currency);
    });
    /* AllfundsBlock */
    $("#allfundscurrency").change(function () {
        var currency = $("select#allfundscurrency option:selected").val();
        window.currencyUpdater.updateCurrency(currency);
    });
    /* Currency picker on top of fundpage */
    $("#currencypickerFundpage").change(function () {
        var currency = $('select#currencypickerFundpage option:selected').val();
        window.currencyUpdater.updateCurrency(currency);
    });
    /* ShareClass picker */
    $("#shareclasspicker").change(function () {
        window.document.location.href = this.options[this.selectedIndex].value;
    });
    $('#graph-view-button').click(function (e) {
        e.preventDefault();
        if (!$("#graph-view").hasClass("in")) {
            $("#graph-view").toggleClass("in");
            $("#table-view").toggleClass("in");
            $("#graph-view-button").toggleClass("collapsed");
            $("#table-view-button").toggleClass("collapsed");
            //$(".changedatearea").css("visibility", "visible");
            $(".choose-currency-area").css("visibility", "hidden");
            $(".changeyearrangearea").css("display", "block");
            $(".changedatetable").css("display", "none");
            if (!fundpagechart) {
                drawGraph();
                setTimeout(function () {
                    fundpagechart.reflow();
                    window.fundpage.changeInDropdown();
                }, 100);
            }
        }
    });
    $('#table-view-button').click(function (e) {
        e.preventDefault();
        if (!$("#table-view").hasClass("in")) {
            $("#graph-view").toggleClass("in");
            $("#table-view").toggleClass("in");
            $("#graph-view-button").toggleClass("collapsed");
            $("#table-view-button").toggleClass("collapsed");
            //$(".changedatearea").css("visibility", "hidden");
            $(".choose-currency-area").css("visibility", "visible");
            $(".changeyearrangearea").css("display", "none");
            $(".changedatetable").css("display", "block");
        }
    });
    $('#tabledateinput').change(function () {
        $(".fundtable").addClass("spin");
        $(".fundtableText").addClass("spin");
        var currency = $('select#currencydd option:selected').val();
        var isin = $(".fundgraph").data("isin");
        var date = $("#tabledateinput").val();
        var webMethod = '/api/FundGathering/GetFundsPerformance?';
        var parameters = { isin: isin, date: date, currency: currency };
        $.getJSON(webMethod, parameters, function (json) {
            $("#tabledatechanged").text("(" + graphtexts.asof + " " + date + ")");
            $("#tabledatechanged").css("color", "#003363");
            $(".fundtable").removeClass("spin");
            $(".fundtableText").removeClass("spin");
            updateFundTableView(json);
        });
    });
});
