"use strict";
require('jquery-match-height');
$(function () {
    /* NumberedListBlock */
    $('.stepRoundArea .StepRoundItem').matchHeight();
    /* SimpleFundPage init */
    $('.fundsimple__profile--item').matchHeight();
    $('.container--fundsimple--fundcost .fundsimple-costItem').matchHeight();
    $('.container--fundsimple--documents .fundsimple-docItem').matchHeight();
    $('.simplefund .fundPageData .fundPageData--item').matchHeight();
    /* ThreeFundsListBlock */
    $('.container--three-funds-list .three-funds-item').matchHeight();
    /* ThreeFundsListBlock */
    $('.product--footer .footerdata div').matchHeight();
    /* Fundcategorypage */
    $('.categories--Items .categories--item').matchHeight();
    $('.officeLocationBlock .location').matchHeight();
});
