require('jquery-validation');
import { trackTradidionalGaEvent, EventInfoCategory, EventInfoAction, } from "../components/GoogleTagManager";
$(function () {
    /* Apsis Subscribe to newsletter */
    $("#subscribtionform").submit(function (event) {
        var $subscribeForm = $(this);
        var isvalidform = $subscribeForm.valid();
        if (isvalidform) {
            var email = $subscribeForm.find(".email").val();
            var Id = $subscribeForm.data("blockid");
            var postData = { email: email, Id: Id };
            $.ajax({
                url: '/api/Apsis/Subscribe/',
                data: postData,
                dataType: "json",
                traditional: true,
                success: function (data) {
                    $(".inputarea form").hide(1000);
                    $(".inputarea .messagearea .messageOk").show();
                    $(".inputarea .messagearea .messageError").hide();
                    $(".inputarea .messagearea").slideDown();
                    trackTradidionalGaEvent(EventInfoCategory.Newsletter, EventInfoAction.Button, "Subscribtion form", Id);
                },
                error: function (data) {
                    $(".inputarea form").hide(1000);
                    $(".inputarea .messagearea .messageOk").hide();
                    $(".inputarea .messagearea .messageError").show();
                    $(".inputarea .messagearea").slideDown();
                }
            });
        }
        event.preventDefault();
    });
    $('#subscribtionform').validate({
        highlight: function (element) {
            $(element).closest('.form-group').addClass('has-error');
        },
        unhighlight: function (element) {
            $(element).closest('.form-group').removeClass('has-error');
        }
    });
    // Subscription block in footer
    $('#FooterSubscription').validate({
        highlight: function (element) {
            $(element).closest('.form-group').addClass('has-error');
        },
        unhighlight: function (element) {
            $(element).closest('.form-group').removeClass('has-error');
        }
    });
    $("#FooterSubscription").submit(function (event) {
        var $subscribeForm = $(this);
        var $inputArea = $subscribeForm.parent();
        var $messageArea = $inputArea.find('.messagearea');
        var $messageOk = $messageArea.find('.messageOk');
        var $messageError = $messageArea.find('.messageError');
        var isvalidform = $subscribeForm.valid();
        if (isvalidform) {
            var email = $subscribeForm.find(".email").val();
            var Id = $subscribeForm.data("blockid");
            var postData = { email: email, Id: Id };
            $.ajax({
                url: '/api/Apsis/Subscribe/',
                data: postData,
                dataType: "json",
                traditional: true,
                success: function (data) {
                    $subscribeForm.hide(1000);
                    $messageOk.show();
                    $messageError.hide();
                    $messageArea.slideDown();
                },
                error: function (data) {
                    // Happens when email is already subscribed
                    $subscribeForm.hide(1000);
                    $messageOk.hide();
                    $messageError.show();
                    $messageArea.slideDown();
                }
            });
            trackTradidionalGaEvent(EventInfoCategory.Newsletter, EventInfoAction.Button, "Subscribtion footer", Id);
        }
        event.preventDefault();
    });
});
