import { trackTradidionalGaEvent, EventInfoCategory, EventInfoAction, } from '@/components/GoogleTagManager';
var campaignFormsBlock = document.getElementsByClassName('CampaignFormsBlock');
if (campaignFormsBlock) {
    // loop through all campaign forms blocks on page (there can be more than one, but most likely only one)
    Array.from(campaignFormsBlock).forEach(function (element) {
        if (element) {
            // set height for image box to same as form box
            var hightBox = element.querySelector('.Formbox');
            var imageBox = element.querySelector('.image-box');
            if (hightBox && imageBox) {
                var clientHeightpx = hightBox.clientHeight + 'px';
                imageBox.setAttribute('style', "height: ".concat(clientHeightpx));
            }
        }
        // check if form exist and add GA tracking event on submit
        var form = element.querySelector('.EPiServerForms');
        if (form) {
            // fetch value from h2 tag
            var h2Elem = element.querySelector('.Form__Title');
            var formTitle_1 = form.id; // default to form id (this is a guid)
            if (h2Elem && h2Elem.textContent) {
                formTitle_1 = h2Elem.textContent;
            }
            // add event listener to submit button and send GTM event
            var submitButton = form.querySelector('button[type=submit]');
            if (submitButton) {
                submitButton.addEventListener('click', function () {
                    trackTradidionalGaEvent(EventInfoCategory.Campaign, EventInfoAction.Button, formTitle_1);
                });
            }
        }
    });
}
