// This file must only be imported once. If you need to use bootstrap datepick. init must be in this file.
// TODO: stop using this version of bootstrap-datepicker
import 'bootstrap-datepicker/js/bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.da.js';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.fi.js';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.no.js';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.sv.js';
var setupDatePicker = function (selector) {
    // Find date format
    var dateFormat = "dd/mm/yyyy";
    var language = $("html").attr("lang");
    if (language === "no") {
        dateFormat = "dd.mm.yyyy";
    }
    else if (language === "sv") {
        dateFormat = "dd-mm-yyyy";
    }
    else if (language === "da") {
        dateFormat = "dd-mm-yyyy";
    }
    else if (language === "fi") {
        dateFormat = "d.m.yyyy";
    }
    var dateOptions = { language: language, autoclose: true, format: dateFormat };
    $(selector).datepicker(dateOptions);
};
$(function () {
    if ($('#dateinputto').length > 0) {
        setupDatePicker('#dateinputto');
    }
    // HistoricReturnCalculator
    if ($('#historicdateinputfrom').length > 0) {
        setupDatePicker('#historicdateinputfrom');
    }
    if ($('#historicdateinputto').length > 0) {
        setupDatePicker('#historicdateinputto');
    }
    /* GermanTaxBlock */
    if ($('#dateinput').length > 0) {
        setupDatePicker('#dateinput');
    }
    /*FundGraphBlock */
    if ($('#tabledateinput').length > 0) {
        setupDatePicker('#tabledateinput');
    }
});
