"use strict";
// Object that contains currency functions
window.currencyUpdater = {};
$(function () {
    window.currencyUpdater.updateCurrency = function (currency) {
        if ($('.allfunds').length > 0) {
            updateAllFunds(currency);
        }
        if ($('.fundgraph').length > 0) {
            window.fundpage.refreshFundTable(currency);
        }
        if ($(".fundpage").length > 0) {
            updateFundHeader(currency);
        }
        if ($(".fundfact").length > 0) {
            updateFundFacts(currency);
        }
        // Update all currency pickers if any on the same page
        $('body').find('.currencypicker').each(function (i, item) {
            $(item).val(currency);
        });
    };
    function getServerData(apiUrl, postData, successfunction) {
        $.ajax({
            url: apiUrl,
            data: postData,
            dataType: "json",
            traditional: true,
            success: function (data) {
                successfunction(data);
            }
        });
    }
    //Refreshes all the data for the AllFunds block / Allfunds Tab block
    function updateAllFunds(currency) {
        var isins = $('#allfundscurrency').data('isins');
        var postData = { isins: isins, currency: currency };
        getServerData('/api/FundGathering/GetFundsPerformanceForIsins', postData, function (data) {
            updateAllFundsSuccess(data, currency);
        });
    }
    function updateAllFundsSuccess(data, currency) {
        $(data)
            .each(function (index, item) {
            $('.allfunds')
                .find('.fundrow')
                .each(function (j, fundrow) {
                var $fundRow = $(fundrow);
                var isin = $fundRow.data('isin');
                if (isin === item.isin) {
                    $fundRow.find('.navDate').html(item.NavDate);
                    $fundRow.find('.lastupdated').html(item.PerformanceDate);
                    $fundRow.find('.price').html(item.Price);
                    $fundRow.find('.thisyear').html(item.ThisYear);
                    $fundRow.find('.lastyear').html(item.LastYear);
                    $fundRow.find('.last3').html(item.Last3Years);
                    $fundRow.find('.last5').html(item.Last5Years);
                    $fundRow.find('.last5').html(item.Last5Years);
                    // Set newly selected currency on the row
                    $fundRow.data('currency', item.Currency);
                }
            });
        });
        // Blank all values that are no longer available in selected currency
        $('.allfunds')
            .find('.fundrow')
            .each(function (index, fundrow) {
            var $fundRow = $(fundrow);
            var rowCurrency = $fundRow.data('currency');
            if (rowCurrency !== currency) {
                $fundRow.find('.lastupdated').html('-');
                $fundRow.find('.price').html('-');
                $fundRow.find('.thisyear').html('-');
                $fundRow.find('.lastyear').html('-');
                $fundRow.find('.last3').html('-');
                $fundRow.find('.last5').html('-');
            }
        });
    }
    //Updates the data header data on the fundpage
    function updateFundHeader(currency) {
        var isin = $('.fundpage').data('isin');
        var postData = { isin: isin, currency: currency };
        getServerData('/api/FundGathering/GetFundheadingData', postData, updateFundHeaderSuccess);
    }
    function updateFundHeaderSuccess(data) {
        $('.fundheaderlatestprice').html(data.Price);
        if ($('.fundheaderlast3').length > 0) {
            $('.fundheaderlast3').html(data.Last3Years);
        }
        else if ($('.fundheaderlast5').length > 0) {
            $('.fundheaderlast5').html(data.Last5Years);
        }
    }
    // Updates the currency dependent data on the facts about the fund block
    function updateFundFacts(currency) {
        var isin = $('.fundpage').data('isin');
        var postData = { isin: isin, currency: currency };
        getServerData('/api/FundGathering/GetFundFact', postData, updateFundFactsSuccess);
    }
    // callback method for function above
    function updateFundFactsSuccess(data) {
        $('.aum').html(data.AUM);
        $('.aumcurrency').html(data.AUMCurrency);
        $('.minpurchase').html(data.Minpurchase);
        if ($('.totalcustomer').length > 0 && data.TotalCustomer !== null) {
            $('.totalcustomer').html(data.TotalCustomer);
        }
    }
});
