"use strict";
$(function () {
    window.skagenweb = {
        changeInPeriodDropdown: changeInPeriodDropdown
    };
    // Replaces all m2 words globally to m-squared
    $("body *").replaceText(/\b(m2)\b/gi, "m<sup>2</sup>");
    /* FundPageListBlock */
    function changeInPeriodDropdown(selector) {
        var $selectDropDown = $(selector);
        var dropDownText = $selectDropDown.find("option:selected").text().toLowerCase();
        var returnText = $selectDropDown.data("return-text") + " " + dropDownText;
        var fundsDiv = $selectDropDown.data("tiles-id");
        var $fundsDiv = $("#" + fundsDiv);
        var isins = $selectDropDown.data("isins");
        var currency = $selectDropDown.data("currency");
        var period = $selectDropDown.val();
        var webMethod = "/api/FundGathering/GetReturnForPeriod?";
        var parameters = { isins: isins, currency: currency, period: period };
        $.getJSON(webMethod, parameters, function (json) {
            $.each(json.FundTileItems, function (i, item) {
                //Find fund tile
                var $fundDiv = $fundsDiv.find("div[data-fund-tile-isin='" + item.Isin + "']");
                //Change values on fund tile
                $fundDiv.find("[data-contentkey='returnDesc']").text(returnText);
                $fundDiv.find("[data-contentkey='fundReturn']").text(item.FundReturn);
                $fundDiv.find("[data-contentkey='benchmarkReturn']").text(item.BenchmarkReturn);
            });
        });
    }
});
