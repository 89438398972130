import Cookies from 'js-cookie';
import './matchheight';
import "../Block/campaignFormsBlock";
$(function () {
    // boostrap tooltips init
    $('[data-toggle="tooltip"]').tooltip();
    /* Accordion panels. Adds css class to panel-heading for styling*/
    $('.panel-collapse').on('show.bs.collapse', function () {
        $(this).siblings('.panel-heading').addClass('opened');
    });
    $('.panel-collapse').on('hide.bs.collapse', function () {
        $(this).siblings('.panel-heading').removeClass('opened');
    });
    /* Header */
    $('.nav-main__toggle a').click(function () {
        $('.nav-main ul').slideToggle('fast');
        return false;
    });
    /* Popover prevent scroll to top of page*/
    $('a.tooltipbutton').on('click', function (e) {
        e.preventDefault();
        return true;
    });
    /* Cookiebar */
    if ($('#cookie-bar').length > 0) {
        if (!Cookies.get('cookiebar')) {
            $("#cookie-bar").show();
            setHeightOnMainClass();
        }
    }
    $('#acceptCookie').click(function () {
        Cookies.set('cookiebar', 'yes', { expires: 365, path: '/' });
        $('#cookie-bar').hide();
        setHeightOnMainClass();
    });
    /* Alertblock */
    $('.container--alert .close').click(function (e) {
        e.preventDefault();
        var closedAlertId = $(e.target).parents('.container--alert').first().attr('id');
        Cookies.set(closedAlertId, 'closed', { path: '/' });
        $('#' + closedAlertId).hide();
    });
    /* APSIS Event block */
    $('#eventselect').change(function () {
        if (!($(this).find('option:first').val() === $(this).find('option:selected').val())) {
            $('.event').each(function () {
                var visible = false;
                $(this).find('.city').each(function () {
                    var html = $(this).html().trim().replace('&nbsp;', '');
                    if (html === $('#eventselect').val()) {
                        visible = true;
                    }
                });
                if (!visible) {
                    $(this).attr('style', 'display:none;');
                }
                else {
                    $(this).attr('style', 'display:block;');
                }
            });
        }
        else {
            $('.event').each(function () {
                $(this).attr('style', 'display:block;');
            });
        }
    });
    function setHeightOnMainClass() {
        if ($(window).width() > 767 && $('.cip-fund-bg').length === 0) {
            // -> We are on a large screen and NOT on the CIP fund pages. Let's adjust to make room for sticky header
            var height = $('.container--header').height() + 20;
            $('.main').css('padding-top', height + 'px');
            $('.blockanchor').css({ 'top': '-' + height + 'px' });
        }
    }
    function is_touch_device() {
        return 'ontouchstart' in window // works on most browsers
            || navigator.maxTouchPoints; // works on IE10/11 and Surface
    }
    /* PaintingDescriptionBlock */
    if ($('#paintingdescription').length > 0) {
        $('.container--paintinginfo__text').text($('#paintingdescription').text());
        $('.container--paintinginfo').show();
    }
    // login
    $('.loginbox input').keydown(function (e) {
        if (e.keyCode === 13) {
            $("#next").focus().click();
            return false;
        }
    });
    /* Norwegian "BankID mobil */
    if ($("#bankidmobilnorform").length > 0) {
        /* Chrome version 68 and newer can not use iframe login */
        if (getChromeVersion() >= 68) {
            $("#bankidmobilnorform #useiframe").val("false");
        }
    }
    var chatwindowObjectReference = null;
    var callbackwindowObjectReference = null;
    /* TrioBlock old block (will delete this code when block is not in use anymore) */
    $('#TrioChatStart').click(function (e) {
        e.preventDefault();
        // Opens a new window or give focus to already opened window (will contain trio callback page)
        if (!chatwindowObjectReference
            || chatwindowObjectReference.closed) {
            chatwindowObjectReference = window.open($(this).attr('href'), 'SKAGENCHAT', 'width=560, height=572, status=0, scrollbars=0, location=0, toolbar=0, menubar=0, resizable=0');
        }
        else {
            chatwindowObjectReference.focus();
        }
    });
    $('#TrioCallbackStart').click(function (e) {
        e.preventDefault();
        // Opens a new window or give focus to already opened window (will contain trio callback page)
        if (!callbackwindowObjectReference
            || callbackwindowObjectReference.closed) {
            callbackwindowObjectReference = window.open($(this).attr('href'), 'SKAGENCALLBACK', 'width=574, height=600, status=0, scrollbars=0, location=0, toolbar=0, menubar=0, resizable=0');
        }
        else {
            callbackwindowObjectReference.focus();
        }
    });
    /* Contact block */
    $('.contactblock .TrioCallbackStart').click(function (e) {
        e.preventDefault();
        // Opens a new window or give focus to already opened window (will contain trio callback page)
        var url = $(this).data('url');
        if (callbackwindowObjectReference === null
            || callbackwindowObjectReference.closed) {
            callbackwindowObjectReference = window.open(url, 'SKAGENCALLBACK', 'width=574, height=400, status=0, scrollbars=0, location=0, toolbar=0, menubar=0, resizable=0');
        }
        else {
            callbackwindowObjectReference.focus();
        }
    });
    $('.contactblock .TrioChatStart').click(function (e) {
        e.preventDefault();
        // Opens a new window or give focus to already opened window (will contain trio chat page)
        var url = $(this).data('url');
        if (chatwindowObjectReference === null
            || chatwindowObjectReference.closed) {
            chatwindowObjectReference = window.open(url, 'SKAGENCHAT', 'width=560, height=572, status=0, scrollbars=0, location=0, toolbar=0, menubar=0, resizable=0');
        }
        else {
            chatwindowObjectReference.focus();
        }
    });
    /* TextMediaBlock */
    $('.TextMediaBlock .showFormButton').click(function (e) {
        e.preventDefault();
        var buttonClicked = this;
        var divId = $(buttonClicked).data('id');
        if (divId) {
            $(buttonClicked).hide();
            $('#' + divId).show();
        }
    });
    /* SocialMediaButtons */
    $('.shareIt').fadeIn();
    $('.shareIt a').click(function (e) {
        e.preventDefault();
        window.open($(this).attr("href"), "formpopup", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=600");
        this.target = "formpopup";
    });
    /* Click event to scroll to top */
    $(window).scroll(function () {
        if ($(this).scrollTop() > 500) {
            $('.scrollToTop').fadeIn();
        }
        else {
            $('.scrollToTop').fadeOut();
        }
    });
    $('.scrollToTop').click(function () {
        $('html, body').animate({ scrollTop: 0 }, 400);
        return false;
    });
    function getChromeVersion() {
        var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
        return raw ? parseInt(raw[2], 10) : false;
    }
});
