"use strict";
$(function () {
    var $relatedArticles = $('.js-article-content .js-article-preview');
    if ($relatedArticles.length > 0) {
        var $firstRelatedArticle = $relatedArticles.first();
        var $lastRelatedArticle = $relatedArticles.last();
        $firstRelatedArticle.addClass('article--preview--first');
        $lastRelatedArticle.addClass('article--preview--last');
    }
});
