"use strict";
require('tablesorter/dist/js/jquery.tablesorter');
// Object that contains SimpleFundList functions
window.SimpleFundList = {};
$(function () {
    SimpleFundList.SetupTableSorter = function (selector) {
        // Add a custom sorter ( Make sure empty values - are last and remove % from numbers)
        $.tablesorter.addParser({
            // set a unique id
            id: 'percentage',
            is: function (s) {
                // return false so this parser is not auto detected
                return false;
            },
            format: function (s) {
                // format your data for normalization
                if (s.trim().length === 1) {
                    // if no value, force low value to put in the end of the list
                    return s.replace('-', '-100000');
                }
                return s.replace('%', '').replace('*', '').replace(/,/g, '').replace(/\s/g, '');
            },
            // set type, either numeric or text
            type: 'numeric'
        });
        $(selector).find('table.tablesorter').tablesorter({
            sortList: [[0, 0]],
            usNumberFormat: true,
            sortInitialOrder: "desc",
            headers: {
                3: {
                    sorter: 'percentage'
                },
                4: {
                    sorter: 'percentage'
                },
                5: {
                    sorter: 'percentage'
                },
                6: {
                    sorter: 'percentage'
                },
                7: {
                    sorter: 'percentage'
                },
                8: {
                    sorter: 'percentage'
                },
                9: {
                    sorter: 'percentage'
                }
            }
        });
    };
    window.SimpleFundList.ReturnChanger = function (selector) {
        // Get new value from select element
        var selectedReturn = $(selector).val();
        // Find containter for value changes
        var $simpleFundListContainer = $(selector).closest('div.SimpleFundsListBlock');
        // Hide alle return values
        $simpleFundListContainer.find('.ReturnValueHeader').hide();
        $simpleFundListContainer.find('.ReturnValue').hide();
        // Show selected return value
        if (selectedReturn === 'Thisyear') {
            $simpleFundListContainer.find('.ThisYear').show();
        }
        else if (selectedReturn === 'Lastyear') {
            $simpleFundListContainer.find('.LastYear').show();
        }
        else if (selectedReturn === 'Last3') {
            $simpleFundListContainer.find('.Last3Years').show();
        }
        else if (selectedReturn === 'Last5') {
            $simpleFundListContainer.find('.Last5Years').show();
        }
        else if (selectedReturn === 'Last10') {
            $simpleFundListContainer.find('.Last10Years').show();
        }
        else if (selectedReturn === 'Alltime') {
            $simpleFundListContainer.find('.SinceStart').show();
        }
        // Will trigger a resort on the table just to make sure that sorting is correct with new values.
        $simpleFundListContainer.find('table.tablesorter').trigger('update');
    };
    if ($('.DistributionFeeListBlock').length > 0) {
        SimpleFundList.SetupTableSorter(this);
    }
    if ($('.SimpleFundsListBlock').length > 0) {
        SimpleFundList.SetupTableSorter(this);
        var select = $(this).find('select.returnChanger');
        // If multiple blocks on same page we need to setup all of them.
        select.each(function (index, selectItem) {
            window.SimpleFundList.ReturnChanger(selectItem);
        });
    }
});
