"use strict";
$(function () {
    function showMoreArticles(listSelector, maxNumberOfArticlesToShow) {
        // Hide "Show more articles"-button when loading articles
        $(listSelector + ' .js-show-more-articles').addClass('hidden');
        var $hiddenThemeArticles = $(listSelector + ' .js-article-preview.hidden');
        var numberOfArticlesToShow = $hiddenThemeArticles.length < maxNumberOfArticlesToShow ? $hiddenThemeArticles.length : maxNumberOfArticlesToShow;
        for (var i = 0; i < numberOfArticlesToShow; i++) {
            $hiddenThemeArticles.eq(i).removeClass('hidden');
        }
        var numberOfHiddenArticlesLeft = $(listSelector + ' .js-article-preview.hidden').length;
        if (numberOfHiddenArticlesLeft > 0) {
            // Show "Show more articles"-button
            $(listSelector + ' .js-show-more-articles').removeClass('hidden');
        }
    }
    $('.js-show-more-theme-articles').click(function () {
        showMoreArticles('.js-theme-article-list', 4);
    });
    $('.js-show-more-advice-articles').click(function () {
        showMoreArticles('.js-advice-article-list', 3);
    });
});
